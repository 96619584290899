import React from 'react'
import styled from '@emotion/styled'
import '../styles.css'
import SiteMetadata from './SiteMetadata'
import { Contexts } from '@swp/components'

const GlobalCSS = styled.div`
  --color-variable-1: ${props => props.theme.variables['color-variable-1']};
  --color-variable-2: ${props => props.theme.variables['color-variable-2']};

  --color-main-1: ${props => props.theme.colors.front1};
  --color-main-2: ${props => props.theme.colors.front2};
  --color-main-3: ${props => props.theme.colors.front3};
  --color-main-4: ${props => props.theme.colors.front4};

  --color-main-bg-1: ${props => props.theme.colors.back1};
  --color-main-bg-2: ${props => props.theme.colors.back2};
  --color-main-bg-3: ${props => props.theme.colors.back3};
  --color-main-bg-4: ${props => props.theme.colors.back4};
`

export default function Layout(props) {
  return (
    <>
      <SiteMetadata title={props.title} description={props.description} />
      <GlobalCSS>
        <Contexts.Lightbox>{props.children}</Contexts.Lightbox>
      </GlobalCSS>
    </>
  )
}
