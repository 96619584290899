import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { useLocation } from '@reach/router'

export default function SEO(props) {
  const {
    site: { siteMetadata },
  } = useStaticQuery(query)
  const title = props.title || siteMetadata.title
  const description = props.description || siteMetadata.description
  const { favicon, ogImage, ogTitle, ogDescription, gsv } = siteMetadata

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      {favicon ? <link rel="shortcut icon" href={favicon} /> : null}
      {ogImage ? <meta name="image" content={ogImage} /> : null}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={ogTitle || title} />
      <meta property="og:description" content={ogDescription || description} />
      {ogImage ? <meta property="og:image" content={ogImage} /> : null}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={ogTitle || title} />
      <meta name="twitter:description" content={ogDescription || description} />
      {ogImage ? <meta name="twitter:image" content={ogImage} /> : null}
      {gsv ? <meta name="google-site-verification" content={gsv} /> : null}
    </Helmet>
  )
}

const query = graphql`
  {
    site {
      siteMetadata {
        title
        description
        favicon
        ogImage
        ogTitle
        ogDescription
        gsv
      }
    }
  }
`
